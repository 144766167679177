import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Bell, Settings, Search } from 'lucide-react';
import { useAuthStore } from '../../stores/authStore';
import UserMenu from './UserMenu';
import NotificationBadge from '../common/NotificationBadge';
import ThemeToggle from '../common/ThemeToggle';

export default function Header() {
  const user = useAuthStore((state) => state.user);
  const navigate = useNavigate();

  return (
    <header className="bg-gradient-to-r from-orange-50 to-orange-100 dark:from-gray-800 dark:to-gray-900 border-b border-orange-200 dark:border-gray-700">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          {/* Search Bar */}
          <div className="flex-1 max-w-xs">
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Search className="h-4 w-4 text-gray-400" />
              </div>
              <input
                type="text"
                placeholder="Search..."
                className="block w-full pl-10 pr-3 py-2 border border-orange-200 rounded-full text-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-transparent bg-white dark:bg-gray-700 dark:border-gray-600"
              />
            </div>
          </div>

          {/* Right side */}
          <div className="flex items-center space-x-4">
            <ThemeToggle />
            
            {/* Notifications */}
            <button className="relative p-2 text-gray-600 hover:text-gray-700 dark:text-gray-300 dark:hover:text-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 rounded-full hover:bg-orange-100 dark:hover:bg-gray-700 transition-colors">
              <span className="sr-only">View notifications</span>
              <Bell className="h-6 w-6" />
              <NotificationBadge count={3} />
            </button>

            {/* Settings */}
            <button 
              onClick={() => navigate('/settings')}
              className="p-2 text-gray-600 hover:text-gray-700 dark:text-gray-300 dark:hover:text-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 rounded-full hover:bg-orange-100 dark:hover:bg-gray-700 transition-colors"
            >
              <span className="sr-only">Settings</span>
              <Settings className="h-6 w-6" />
            </button>

            {/* Profile dropdown */}
            <UserMenu user={user} />
          </div>
        </div>
      </div>
    </header>
  );
}