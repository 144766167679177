import React from 'react';
import { IndianRupee } from 'lucide-react';
import { formatToIndianCrores } from '../../utils/currency';

interface CurrencyDisplayProps {
  amount: number;
  className?: string;
  showCrores?: boolean;
}

export default function CurrencyDisplay({ 
  amount, 
  className = '',
  showCrores = false 
}: CurrencyDisplayProps) {
  const formattedAmount = showCrores ? formatToIndianCrores(amount) : formatToIndianCrores(amount);

  return (
    <div className={`flex items-center ${className}`}>
      <IndianRupee className="h-4 w-4 text-gray-600 mr-1" />
      <span>{formattedAmount}</span>
    </div>
  );
}