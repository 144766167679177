import React from 'react';
import { Search } from 'lucide-react';
import type { TransactionFilter } from '../../types/finance';

interface Props {
  filters: TransactionFilter;
  onFilterChange: (filters: TransactionFilter) => void;
}

export default function TransactionFilters({ filters, onFilterChange }: Props) {
  return (
    <div className="p-4 border-b border-gray-200 bg-gray-50 space-y-4 sm:space-y-0 sm:flex sm:items-center sm:justify-between sm:space-x-4">
      <div className="flex-1 min-w-0">
        <div className="relative rounded-md shadow-sm">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <Search className="h-5 w-5 text-gray-400" />
          </div>
          <input
            type="text"
            value={filters.search || ''}
            onChange={(e) => onFilterChange({ ...filters, search: e.target.value })}
            className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500 sm:text-sm"
            placeholder="Search transactions..."
          />
        </div>
      </div>

      <div className="flex items-center space-x-4">
        <select
          value={filters.status}
          onChange={(e) => onFilterChange({ ...filters, status: e.target.value as TransactionFilter['status'] })}
          className="block pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm rounded-md"
        >
          <option value="all">All Status</option>
          <option value="paid">Paid</option>
          <option value="pending">Pending</option>
          <option value="overdue">Overdue</option>
          <option value="cancelled">Cancelled</option>
        </select>

        <input
          type="date"
          value={filters.startDate || ''}
          onChange={(e) => onFilterChange({ ...filters, startDate: e.target.value })}
          className="block pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm rounded-md"
        />

        <input
          type="date"
          value={filters.endDate || ''}
          onChange={(e) => onFilterChange({ ...filters, endDate: e.target.value })}
          className="block pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm rounded-md"
        />
      </div>
    </div>
  );
}