import React, { useRef, useState } from 'react';
import { Camera } from 'lucide-react';
import ProfilePicture from './ProfilePicture';

interface ProfilePictureUploadProps {
  currentImage?: string;
  onUpload: (file: File) => void;
}

export default function ProfilePictureUpload({ 
  currentImage,
  onUpload 
}: ProfilePictureUploadProps) {
  const [previewUrl, setPreviewUrl] = useState<string | undefined>(currentImage);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result as string);
      };
      reader.readAsDataURL(file);
      onUpload(file);
    }
  };

  return (
    <div className="relative group">
      <ProfilePicture src={previewUrl} size="lg" className="group-hover:opacity-75 transition-opacity" />
      <button
        onClick={() => fileInputRef.current?.click()}
        className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-full opacity-0 group-hover:opacity-100 transition-opacity"
      >
        <Camera className="h-8 w-8 text-white" />
      </button>
      <input
        ref={fileInputRef}
        type="file"
        accept="image/*"
        className="hidden"
        onChange={handleFileChange}
      />
    </div>
  );
}