import React from 'react';
import { Link } from 'react-router-dom';
import { useAuthStore } from '../stores/authStore';
import { 
  Recycle, 
  LayoutDashboard, 
  FileText, 
  BarChart,
  Truck,
  Users,
  Shield
} from 'lucide-react';
import CurrencyDisplay from '../components/common/CurrencyDisplay';

export default function LandingPage() {
  const user = useAuthStore((state) => state.user);

  return (
    <div className="bg-white">
      {/* Hero Section */}
      <div className="relative bg-gray-50">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8 lg:py-24">
          <div className="space-y-8">
            <div className="space-y-4">
              <div className="flex items-center gap-3">
                <Recycle className="h-12 w-12 text-green-600" />
                <div>
                  <h1 className="text-4xl font-bold text-gray-900">
                    {user ? (
                      `Welcome, ${user.firstName} from ${user.organization}!`
                    ) : (
                      'Welcome to Deshwal Waste Management'
                    )}
                  </h1>
                  <p className="text-lg text-gray-600">
                    Manage your IT assets efficiently with our comprehensive solution
                  </p>
                </div>
              </div>
            </div>

            <div className="flex gap-4">
              <Link
                to={user ? '/dashboard' : '/login'}
                className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700"
              >
                Get Started
              </Link>
              {!user && (
                <Link
                  to="/register"
                  className="inline-flex items-center px-6 py-3 border border-gray-300 text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                >
                  Learn More
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Stats Section */}
      <div className="bg-white py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-5 sm:grid-cols-4">
            <div className="bg-gray-50 overflow-hidden shadow rounded-lg">
              <div className="p-5">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <LayoutDashboard className="h-6 w-6 text-green-600" />
                  </div>
                  <div className="ml-5">
                    <p className="text-sm font-medium text-gray-500">Active Orders</p>
                    <p className="text-2xl font-semibold text-gray-900">24</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 overflow-hidden shadow rounded-lg">
              <div className="p-5">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <FileText className="h-6 w-6 text-green-600" />
                  </div>
                  <div className="ml-5">
                    <p className="text-sm font-medium text-gray-500">Processed Assets</p>
                    <p className="text-2xl font-semibold text-gray-900">1,234</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 overflow-hidden shadow rounded-lg">
              <div className="p-5">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <CurrencyDisplay amount={50000000} showCrores={true} />
                  </div>
                  <div className="ml-5">
                    <p className="text-sm font-medium text-gray-500">Revenue</p>
                    <p className="text-2xl font-semibold text-gray-900">5 Cr</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 overflow-hidden shadow rounded-lg">
              <div className="p-5">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <BarChart className="h-6 w-6 text-green-600" />
                  </div>
                  <div className="ml-5">
                    <p className="text-sm font-medium text-gray-500">Growth</p>
                    <p className="text-2xl font-semibold text-gray-900">+32%</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div className="bg-gray-50 py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold text-gray-900">
              Comprehensive IT Asset Management
            </h2>
            <p className="mt-4 text-lg text-gray-500">
              Everything you need to manage your IT assets efficiently and securely
            </p>
          </div>

          <div className="mt-12">
            <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4">
              {[
                {
                  name: 'Asset Tracking',
                  description: 'Real-time tracking of IT assets and their lifecycle status',
                  icon: LayoutDashboard,
                },
                {
                  name: 'Secure Disposal',
                  description: 'Certified e-waste disposal with environmental compliance',
                  icon: Shield,
                },
                {
                  name: 'Fleet Management',
                  description: 'Efficient logistics and transportation management',
                  icon: Truck,
                },
                {
                  name: 'Client Portal',
                  description: 'Dedicated portal for client communication and reporting',
                  icon: Users,
                },
              ].map((feature) => {
                const Icon = feature.icon;
                return (
                  <div key={feature.name} className="bg-white overflow-hidden shadow rounded-lg">
                    <div className="p-6">
                      <div className="flex items-center">
                        <div className="flex-shrink-0">
                          <Icon className="h-8 w-8 text-green-600" />
                        </div>
                        <div className="ml-4">
                          <h3 className="text-lg font-medium text-gray-900">{feature.name}</h3>
                          <p className="mt-2 text-sm text-gray-500">{feature.description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}