import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { supabase } from './config/supabase';
import LoginPage from './pages/auth/LoginPage';
import RegisterPage from './pages/auth/RegisterPage';
import DashboardLayout from './components/layout/DashboardLayout';
import LandingPage from './pages/LandingPage';
import Footer from './components/layout/Footer';
import ChatWidget from './components/chat/ChatWidget';
import { useAuthStore } from './stores/authStore';
import { useThemeStore } from './stores/themeStore';
import AuthCallback from './pages/auth/AuthCallback';

const queryClient = new QueryClient();

function PrivateRoute({ children }: { children: React.ReactNode }) {
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  return isAuthenticated ? <>{children}</> : <Navigate to="/login" />;
}

function App() {
  const isDarkMode = useThemeStore((state) => state.isDarkMode);
  const { login } = useAuthStore();

  useEffect(() => {
    // Handle initial session
    supabase.auth.getSession().then(({ data: { session } }) => {
      if (session?.user) {
        login({
          email: session.user.email || '',
          password: '', // Not needed for session restoration
        });
      }
    });

    // Listen for auth changes
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      if (session?.user) {
        login({
          email: session.user.email || '',
          password: '', // Not needed for session restoration
        });
      }
    });

    return () => subscription.unsubscribe();
  }, [login]);

  return (
    <div className={isDarkMode ? 'dark' : ''}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <div className="min-h-screen flex flex-col">
            <div className="flex-grow">
              <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/register" element={<RegisterPage />} />
                <Route path="/auth/callback" element={<AuthCallback />} />
                
                <Route
                  path="/*"
                  element={
                    <PrivateRoute>
                      <DashboardLayout />
                    </PrivateRoute>
                  }
                />
              </Routes>
            </div>
            <Footer />
          </div>
          <ChatWidget />
        </BrowserRouter>
      </QueryClientProvider>
    </div>
  );
}

export default App;