import { create } from 'zustand';
import { supabase } from '../config/supabase';
import type { AuthState, LoginCredentials, RegistrationData } from '../types/auth';

export const useAuthStore = create<AuthState>((set) => ({
  user: null,
  isAuthenticated: false,
  isLoading: false,
  error: null,

  login: async (credentials: LoginCredentials) => {
    set({ isLoading: true, error: null });
    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email: credentials.email,
        password: credentials.password,
      });

      if (error) throw error;

      if (data.user) {
        set({
          isAuthenticated: true,
          user: {
            id: data.user.id,
            firstName: data.user.user_metadata.firstName || '',
            lastName: data.user.user_metadata.lastName || '',
            email: data.user.email || '',
            organization: data.user.user_metadata.organization || '',
            role: data.user.user_metadata.role || 'team_member',
            verified: data.user.email_confirmed_at !== null,
            createdAt: data.user.created_at,
            profilePicture: data.user.user_metadata.profilePicture,
          },
          isLoading: false,
        });
      }
    } catch (error) {
      set({ 
        error: error instanceof Error ? error.message : 'Invalid credentials', 
        isLoading: false 
      });
    }
  },

  register: async (data: RegistrationData) => {
    set({ isLoading: true, error: null });
    try {
      const { data: authData, error } = await supabase.auth.signUp({
        email: data.email,
        password: data.password,
        options: {
          data: {
            firstName: data.firstName,
            lastName: data.lastName,
            organization: data.organization,
            role: data.role,
          },
          emailRedirectTo: `${window.location.origin}/auth/callback`,
        },
      });

      if (error) throw error;

      if (authData.user) {
        set({
          isAuthenticated: true,
          user: {
            id: authData.user.id,
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            organization: data.organization,
            role: data.role,
            verified: false,
            createdAt: authData.user.created_at,
          },
          isLoading: false,
        });
      }
    } catch (error) {
      set({ 
        error: error instanceof Error ? error.message : 'Registration failed', 
        isLoading: false 
      });
    }
  },

  logout: async () => {
    await supabase.auth.signOut();
    set({
      user: null,
      isAuthenticated: false,
      error: null,
    });
  },

  clearError: () => {
    set({ error: null });
  },

  updateProfilePicture: (imageUrl: string) => {
    set((state) => ({
      user: state.user ? { ...state.user, profilePicture: imageUrl } : null,
    }));
  },
}));