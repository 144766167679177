import React, { useState } from 'react';
import { Mail, Check } from 'lucide-react';
import { supabase } from '../../config/supabase';
import LoadingSpinner from '../common/LoadingSpinner';

interface Props {
  email: string;
  onVerified: () => void;
}

export default function EmailVerification({ email, onVerified }: Props) {
  const [isVerifying, setIsVerifying] = useState(false);
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSendVerification = async () => {
    setIsVerifying(true);
    setError(null);
    
    try {
      const { error: signUpError } = await supabase.auth.signInWithOtp({
        email,
        options: {
          emailRedirectTo: `${window.location.origin}/auth/callback`,
        },
      });

      if (signUpError) throw signUpError;

      setIsCodeSent(true);
      onVerified(); // Mark as verified since we're using magic link
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Failed to send verification email');
    } finally {
      setIsVerifying(false);
    }
  };

  if (isCodeSent) {
    return (
      <div className="mt-2 text-sm text-green-600 flex items-center">
        <Check className="h-5 w-5 mr-2" />
        <span>Verification email sent! Please check your inbox.</span>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {error && (
        <div className="text-sm text-red-600">
          {error}
        </div>
      )}

      <button
        onClick={handleSendVerification}
        disabled={isVerifying}
        className="w-full flex justify-center items-center px-4 py-2 text-sm font-medium text-white bg-green-600 rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:opacity-50 disabled:cursor-not-allowed"
      >
        {isVerifying ? (
          <LoadingSpinner size="sm" className="text-white" />
        ) : (
          <>
            <Mail className="h-5 w-5 mr-2" />
            Send Verification Email
          </>
        )}
      </button>
    </div>
  );
}