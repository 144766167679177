import React from 'react';
import { IndianRupee, CreditCard, Clock, CalendarCheck } from 'lucide-react';
import { format } from 'date-fns';
import type { FinancialSummary } from '../../types/finance';

interface Props {
  summary: FinancialSummary;
}

export default function FinancialSummaryCards({ summary }: Props) {
  const formatCurrency = (amount: number) => {
    return `₹${amount.toLocaleString('en-IN')}`;
  };

  const cards = [
    {
      title: 'Total Outstanding',
      value: formatCurrency(summary.totalOutstanding),
      icon: IndianRupee,
      color: 'text-blue-600',
      bgColor: 'bg-blue-100',
    },
    {
      title: 'Total Paid',
      value: formatCurrency(summary.totalPaid),
      icon: CreditCard,
      color: 'text-green-600',
      bgColor: 'bg-green-100',
    },
    {
      title: 'Due Payments',
      value: formatCurrency(summary.duePayments),
      icon: Clock,
      color: 'text-yellow-600',
      bgColor: 'bg-yellow-100',
    },
    {
      title: 'Last Payment',
      value: formatCurrency(summary.lastPaymentAmount),
      subtitle: format(new Date(summary.lastPaymentDate), 'MMM d, yyyy'),
      icon: CalendarCheck,
      color: 'text-purple-600',
      bgColor: 'bg-purple-100',
    },
  ];

  return (
    <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
      {cards.map((card) => {
        const Icon = card.icon;
        return (
          <div
            key={card.title}
            className="bg-white overflow-hidden shadow rounded-lg"
          >
            <div className="p-5">
              <div className="flex items-center">
                <div className={`flex-shrink-0 rounded-md p-3 ${card.bgColor}`}>
                  <Icon className={`h-6 w-6 ${card.color}`} />
                </div>
                <div className="ml-5 w-0 flex-1">
                  <dl>
                    <dt className="text-sm font-medium text-gray-500 truncate">
                      {card.title}
                    </dt>
                    <dd className="text-lg font-semibold text-gray-900">
                      {card.value}
                    </dd>
                    {card.subtitle && (
                      <dd className="text-sm text-gray-500">{card.subtitle}</dd>
                    )}
                  </dl>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}