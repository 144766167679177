export function formatToIndianCrores(amount: number): string {
  const crore = 10000000; // 1 crore = 10 million
  const lakh = 100000; // 1 lakh = 100 thousand
  
  if (amount >= crore) {
    return `${(amount / crore).toFixed(0)} Cr`;
  } else if (amount >= lakh) {
    return `${(amount / lakh).toFixed(0)} L`;
  }
  
  return new Intl.NumberFormat('en-IN', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(amount);
}

export function formatCurrency(amount: number): string {
  return new Intl.NumberFormat('en-IN', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(amount);
}