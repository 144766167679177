import React, { useState } from 'react';
import { format } from 'date-fns';
import { IndianRupee } from 'lucide-react';
import FinancialSummaryCards from '../components/finance/FinancialSummaryCards';
import TransactionHistory from '../components/finance/TransactionHistory';
import TransactionFilters from '../components/finance/TransactionFilters';
import type { Transaction, TransactionFilter, FinancialSummary } from '../types/finance';

// Mock data - replace with actual API calls
const mockSummary: FinancialSummary = {
  totalOutstanding: 45000,
  totalPaid: 150000,
  duePayments: 15000,
  lastPaymentDate: '2024-03-10',
  lastPaymentAmount: 5000,
};

const mockTransactions: Transaction[] = [
  {
    id: '1',
    date: '2024-03-10',
    description: 'Asset Disposal Service - March Batch',
    invoiceNumber: 'INV-2024-001',
    amount: 5000,
    status: 'paid',
    paymentMethod: 'Credit Card',
  },
  {
    id: '2',
    date: '2024-03-05',
    description: 'Data Wiping Services - Batch #2458',
    invoiceNumber: 'INV-2024-002',
    amount: 3500,
    status: 'pending',
  },
  {
    id: '3',
    date: '2024-02-28',
    description: 'E-Waste Collection - February',
    invoiceNumber: 'INV-2024-003',
    amount: 7500,
    status: 'overdue',
    notes: 'Payment reminder sent',
  },
];

export default function FinancePage() {
  const [filters, setFilters] = useState<TransactionFilter>({
    status: 'all',
  });

  const handlePayment = () => {
    // TODO: Implement payment gateway integration
    window.open('https://payment-gateway.example.com', '_blank');
  };

  const filteredTransactions = mockTransactions.filter((transaction) => {
    if (filters.status !== 'all' && transaction.status !== filters.status) {
      return false;
    }

    if (filters.search) {
      const searchLower = filters.search.toLowerCase();
      return (
        transaction.description.toLowerCase().includes(searchLower) ||
        transaction.invoiceNumber.toLowerCase().includes(searchLower)
      );
    }

    if (filters.startDate && filters.endDate) {
      const transactionDate = new Date(transaction.date);
      const start = new Date(filters.startDate);
      const end = new Date(filters.endDate);
      return transactionDate >= start && transactionDate <= end;
    }

    return true;
  });

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-semibold text-gray-900">Finance</h1>
        <button
          onClick={handlePayment}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
        >
          <IndianRupee className="h-5 w-5 mr-2" />
          Make a Payment
        </button>
      </div>

      <FinancialSummaryCards summary={mockSummary} />

      <div className="bg-white shadow rounded-lg">
        <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
          <h2 className="text-lg font-medium text-gray-900">Transaction History</h2>
        </div>
        
        <TransactionFilters
          filters={filters}
          onFilterChange={setFilters}
        />

        <TransactionHistory transactions={filteredTransactions} />
      </div>
    </div>
  );
}