import React from 'react';
import { Package, Clock, Bell } from 'lucide-react';
import WelcomeBanner from '../components/dashboard/WelcomeBanner';
import StatusTable from '../components/dashboard/StatusTable';
import ProgressTracker from '../components/dashboard/ProgressTracker';
import CurrencyDisplay from '../components/common/CurrencyDisplay';

const stats = [
  {
    name: 'Opportunities',
    value: '8',
    icon: Package,
    change: '+2 this week',
  },
  {
    name: 'Pending Activities',
    value: '12',
    icon: Clock,
    change: '4 due today',
  },
  {
    name: 'Notifications',
    value: '5',
    icon: Bell,
    change: '3 new',
  },
];

export default function DashboardPage() {
  return (
    <div className="space-y-6">
      <WelcomeBanner />

      <div className="grid grid-cols-1 gap-5 sm:grid-cols-3">
        {stats.map((item) => {
          const Icon = item.icon;
          return (
            <div
              key={item.name}
              className="bg-white overflow-hidden shadow rounded-lg"
            >
              <div className="p-5">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <Icon className="h-6 w-6 text-green-600" />
                  </div>
                  <div className="ml-5 w-0 flex-1">
                    <dl>
                      <dt className="text-sm font-medium text-gray-500 truncate">
                        {item.name}
                      </dt>
                      <dd className="text-lg font-semibold text-gray-900">
                        {item.value}
                      </dd>
                      <dd className="text-sm text-gray-500">{item.change}</dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <StatusTable />
      <ProgressTracker />

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="bg-white p-6 rounded-lg shadow">
          <h3 className="text-lg font-medium text-gray-900 mb-4">
            Recent Activities
          </h3>
          <div className="space-y-4">
            {[
              'New order received from Rajnish Test 1',
              'Data wiping completed for Asset Batch #2458',
              'Certificate generated for INV-2024-001',
              'Payment received for INV-2024-002',
              'Pickup scheduled for tomorrow at 10 AM',
            ].map((activity, index) => (
              <div key={index} className="flex items-center space-x-3 text-sm">
                <div className="w-2 h-2 bg-green-500 rounded-full" />
                <span>{activity}</span>
              </div>
            ))}
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg shadow">
          <h3 className="text-lg font-medium text-gray-900 mb-4">
            Quick Actions
          </h3>
          <div className="grid grid-cols-2 gap-4">
            <button className="p-4 text-left bg-gray-50 rounded-lg hover:bg-gray-100">
              <Package className="h-6 w-6 text-green-500 mb-2" />
              <span className="block font-medium">New Opportunity</span>
            </button>
            <button className="p-4 text-left bg-gray-50 rounded-lg hover:bg-gray-100">
              <Bell className="h-6 w-6 text-green-500 mb-2" />
              <span className="block font-medium">Contact Support</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}