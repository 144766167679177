import React from 'react';
import { FileText, Download, Clock } from 'lucide-react';
import { format } from 'date-fns';
import type { Document } from '../../types/document';

interface DocumentGridProps {
  documents: Document[];
  onDownload: (document: Document) => void;
}

export default function DocumentGrid({ documents, onDownload }: DocumentGridProps) {
  const getStatusBadge = (status: Document['status']) => {
    const styles = {
      available: 'bg-green-100 text-green-800',
      pending: 'bg-yellow-100 text-yellow-800',
      processing: 'bg-blue-100 text-blue-800',
    };

    return (
      <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${styles[status]}`}>
        {status.charAt(0).toUpperCase() + status.slice(1)}
      </span>
    );
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {documents.map((doc) => (
        <div key={doc.id} className="bg-white rounded-lg shadow-sm border border-gray-200 hover:shadow-md transition-shadow">
          <div className="p-6">
            <div className="flex items-start justify-between">
              <div className="flex items-start">
                <FileText className="h-8 w-8 text-green-500 mt-1" />
                <div className="ml-3">
                  <h3 className="text-lg font-medium text-gray-900">{doc.title}</h3>
                  {getStatusBadge(doc.status)}
                </div>
              </div>
              <button
                onClick={() => onDownload(doc)}
                className="text-gray-400 hover:text-gray-500"
                disabled={doc.status !== 'available'}
              >
                <Download className="h-5 w-5" />
              </button>
            </div>
            
            {doc.description && (
              <p className="mt-2 text-sm text-gray-500">{doc.description}</p>
            )}
            
            <div className="mt-4 grid grid-cols-2 gap-4 text-sm text-gray-500">
              <div className="flex items-center">
                <Clock className="h-4 w-4 mr-1" />
                <span>{format(new Date(doc.lastModified), 'MMM d, yyyy')}</span>
              </div>
              <div className="text-right">
                Version {doc.version}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}