import React from 'react';
import { Link, useLocation, Routes, Route, Navigate } from 'react-router-dom';
import { 
  LayoutDashboard, 
  Package, 
  FileText, 
  DollarSign, 
  LifeBuoy,
  Recycle 
} from 'lucide-react';
import Header from './Header';
import DashboardPage from '../../pages/DashboardPage';
import OrdersPage from '../../pages/OrdersPage';
import DocumentsPage from '../../pages/DocumentsPage';
import FinancePage from '../../pages/FinancePage';
import SupportPage from '../../pages/SupportPage';
import ProfilePage from '../../pages/ProfilePage';

const navigation = [
  { name: 'Dashboard', href: '/dashboard', icon: LayoutDashboard },
  { name: 'Orders', href: '/orders', icon: Package },
  { name: 'Documents', href: '/documents', icon: FileText },
  { name: 'Finance', href: '/finance', icon: DollarSign },
  { name: 'Support', href: '/support', icon: LifeBuoy },
];

export default function DashboardLayout() {
  const location = useLocation();

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="flex h-screen">
        {/* Sidebar */}
        <div className="w-64 bg-gradient-to-b from-orange-50 to-white dark:from-gray-800 dark:to-gray-900 shadow-lg">
          <div className="flex flex-col h-full">
            <div className="flex items-center gap-3 h-16 px-4 border-b border-orange-200 dark:border-gray-700">
              <Recycle className="h-8 w-8 text-orange-600 dark:text-orange-500" />
              <div>
                <h1 className="text-lg font-bold text-gray-900 dark:text-white">Deshwal</h1>
                <p className="text-xs text-gray-600 dark:text-gray-400">Waste Management</p>
              </div>
            </div>
            
            <nav className="flex-1 px-4 py-4 space-y-1">
              {navigation.map((item) => {
                const Icon = item.icon;
                const isActive = location.pathname === item.href;
                
                return (
                  <Link
                    key={item.name}
                    to={item.href}
                    className={`flex items-center px-4 py-2 text-sm font-medium rounded-lg transition-colors ${
                      isActive
                        ? 'bg-orange-100 text-orange-700 dark:bg-orange-900 dark:text-orange-200'
                        : 'text-gray-600 hover:bg-orange-50 dark:text-gray-300 dark:hover:bg-gray-800'
                    }`}
                  >
                    <Icon className="w-5 h-5 mr-3" />
                    {item.name}
                  </Link>
                );
              })}
            </nav>
          </div>
        </div>

        {/* Main content */}
        <div className="flex-1 flex flex-col overflow-hidden">
          <Header />
          <main className="flex-1 overflow-auto p-6">
            <Routes>
              <Route index element={<Navigate to="/dashboard" replace />} />
              <Route path="/dashboard" element={<DashboardPage />} />
              <Route path="/orders" element={<OrdersPage />} />
              <Route path="/documents" element={<DocumentsPage />} />
              <Route path="/finance" element={<FinancePage />} />
              <Route path="/support" element={<SupportPage />} />
              <Route path="/profile" element={<ProfilePage />} />
            </Routes>
          </main>
        </div>
      </div>
    </div>
  );
}