import React, { useState } from 'react';
import { FileText, Search } from 'lucide-react';
import type { Document, DocumentType } from '../types/document';
import DocumentGrid from '../components/documents/DocumentGrid';

const mockDocuments: Document[] = [
  {
    id: '1',
    title: 'Data Wiping Certificate',
    type: 'data-wiping',
    dateCreated: '2024-03-15',
    lastModified: '2024-03-15',
    version: '1.0',
    signedBy: 'John Smith',
    downloadUrl: '/documents/data-wiping-cert.pdf',
    status: 'available',
    description: 'Certificate confirming secure data erasure following DoD standards',
  },
  {
    id: '2',
    title: 'Destruction Certificate',
    type: 'destruction',
    dateCreated: '2024-03-14',
    lastModified: '2024-03-14',
    version: '1.0',
    signedBy: 'Sarah Johnson',
    downloadUrl: '/documents/destruction-cert.pdf',
    status: 'available',
    description: 'Physical destruction certification for end-of-life assets',
  },
  {
    id: '3',
    title: 'Green Certificate',
    type: 'green',
    dateCreated: '2024-03-13',
    lastModified: '2024-03-13',
    version: '1.0',
    signedBy: 'Mike Wilson',
    downloadUrl: '/documents/green-cert.pdf',
    status: 'available',
    description: 'Environmental compliance certification for recycling process',
  },
  {
    id: '4',
    title: 'Form 6',
    type: 'form-6',
    dateCreated: '2024-03-12',
    lastModified: '2024-03-12',
    version: '1.0',
    signedBy: 'David Brown',
    downloadUrl: '/documents/form-6.pdf',
    status: 'available',
    description: 'Regulatory compliance documentation',
  },
];

export default function DocumentsPage() {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedType, setSelectedType] = useState<DocumentType | 'all'>('all');

  const handleDownload = (document: Document) => {
    // TODO: Implement actual download logic
    console.log('Downloading:', document.title);
  };

  const filteredDocuments = mockDocuments.filter((doc) => {
    if (selectedType !== 'all' && doc.type !== selectedType) return false;
    if (searchQuery) {
      const query = searchQuery.toLowerCase();
      return (
        doc.title.toLowerCase().includes(query) ||
        doc.description?.toLowerCase().includes(query)
      );
    }
    return true;
  });

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-semibold text-gray-900">Documents</h1>
      </div>

      <div className="bg-white shadow rounded-lg">
        <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
          <div className="flex flex-col sm:flex-row gap-4">
            <div className="flex-1">
              <div className="relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Search className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="text"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500 sm:text-sm"
                  placeholder="Search documents..."
                />
              </div>
            </div>
            
            <select
              value={selectedType}
              onChange={(e) => setSelectedType(e.target.value as DocumentType | 'all')}
              className="block w-full sm:w-48 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm rounded-md"
            >
              <option value="all">All Documents</option>
              <option value="data-wiping">Data Wiping Certificates</option>
              <option value="destruction">Destruction Certificates</option>
              <option value="green">Green Certificates</option>
              <option value="form-6">Form 6</option>
            </select>
          </div>
        </div>

        <div className="p-6">
          <DocumentGrid 
            documents={filteredDocuments}
            onDownload={handleDownload}
          />
        </div>
      </div>
    </div>
  );
}