import React from 'react';
import { format } from 'date-fns';
import { useAuthStore } from '../../stores/authStore';
import { Package, Clock, CheckCircle } from 'lucide-react';

export default function WelcomeBanner() {
  const user = useAuthStore((state) => state.user);
  
  if (!user) {
    return null;
  }

  const metrics = [
    {
      label: 'Active Orders',
      value: '12',
      icon: Package,
      color: 'text-blue-600',
      bgColor: 'bg-blue-100',
    },
    {
      label: 'In Progress',
      value: '5',
      icon: Clock,
      color: 'text-yellow-600',
      bgColor: 'bg-yellow-100',
    },
    {
      label: 'Completed',
      value: '128',
      icon: CheckCircle,
      color: 'text-green-600',
      bgColor: 'bg-green-100',
    },
  ];

  return (
    <div className="bg-white rounded-lg shadow-lg p-6 mb-6">
      <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between">
        <div className="mb-4 lg:mb-0">
          <h1 className="text-2xl font-bold text-gray-900">
            Welcome back, {user.firstName}!
          </h1>
          <p className="text-gray-600 mt-1">
            {format(new Date(), 'EEEE, MMMM d, yyyy | h:mm a')}
          </p>
          <p className="text-sm text-gray-500 mt-2">
            Track your IT asset disposition progress and manage your orders efficiently
          </p>
        </div>

        <div className="grid grid-cols-3 gap-4">
          {metrics.map((metric) => {
            const Icon = metric.icon;
            return (
              <div key={metric.label} className="text-center">
                <div className={`inline-flex p-3 rounded-full ${metric.bgColor} mb-2`}>
                  <Icon className={`h-6 w-6 ${metric.color}`} />
                </div>
                <div className="text-2xl font-semibold text-gray-900">{metric.value}</div>
                <div className="text-sm text-gray-600">{metric.label}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}