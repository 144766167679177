import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { LogOut, Settings, HelpCircle } from 'lucide-react';
import { useAuthStore } from '../../stores/authStore';
import type { User } from '../../types/auth';
import ProfilePicture from '../profile/ProfilePicture';

interface Props {
  user: User | null;
}

export default function UserMenu({ user }: Props) {
  const logout = useAuthStore((state) => state.logout);

  if (!user) {
    return (
      <div className="flex items-center space-x-4">
        <Link
          to="/login"
          className="text-sm font-medium text-gray-700 hover:text-gray-800"
        >
          Sign in
        </Link>
        <Link
          to="/register"
          className="text-sm font-medium text-white bg-green-600 hover:bg-green-700 px-4 py-2 rounded-md"
        >
          Sign up
        </Link>
      </div>
    );
  }

  return (
    <Menu as="div" className="relative ml-3">
      <Menu.Button className="flex items-center max-w-xs text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
        <span className="sr-only">Open user menu</span>
        <div className="flex items-center space-x-3">
          <ProfilePicture 
            src={user.profilePicture} 
            alt={`${user.firstName} ${user.lastName}`}
            size="sm"
          />
          <div className="hidden md:block text-left">
            <p className="text-sm font-medium text-gray-700">
              {user.firstName} {user.lastName}
            </p>
            <p className="text-xs text-gray-500">{user.organization}</p>
          </div>
        </div>
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <Menu.Item>
            {({ active }) => (
              <Link
                to="/profile"
                className={`${
                  active ? 'bg-gray-100' : ''
                } flex px-4 py-2 text-sm text-gray-700 items-center`}
              >
                <ProfilePicture 
                  src={user.profilePicture}
                  size="sm"
                  className="mr-3"
                />
                Your Profile
              </Link>
            )}
          </Menu.Item>

          <Menu.Item>
            {({ active }) => (
              <Link
                to="/settings"
                className={`${
                  active ? 'bg-gray-100' : ''
                } flex px-4 py-2 text-sm text-gray-700 items-center`}
              >
                <Settings className="mr-3 h-5 w-5 text-gray-400" />
                Settings
              </Link>
            )}
          </Menu.Item>

          <Menu.Item>
            {({ active }) => (
              <Link
                to="/support"
                className={`${
                  active ? 'bg-gray-100' : ''
                } flex px-4 py-2 text-sm text-gray-700 items-center`}
              >
                <HelpCircle className="mr-3 h-5 w-5 text-gray-400" />
                Help & Support
              </Link>
            )}
          </Menu.Item>

          <Menu.Item>
            {({ active }) => (
              <button
                onClick={logout}
                className={`${
                  active ? 'bg-gray-100' : ''
                } flex w-full px-4 py-2 text-sm text-gray-700 items-center`}
              >
                <LogOut className="mr-3 h-5 w-5 text-gray-400" />
                Sign out
              </button>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}