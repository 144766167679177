import React from 'react';

interface Props {
  count: number;
}

export default function NotificationBadge({ count }: Props) {
  if (count === 0) return null;

  return (
    <span className="absolute top-0 right-0 -mt-1 -mr-1 flex h-4 w-4 items-center justify-center rounded-full bg-red-500 text-xs font-medium text-white">
      {count > 9 ? '9+' : count}
    </span>
  );
}